/* .tech-background .slide img{
    height: 720px;
} */

* {
    font-family: 'Noto Sans', sans-serif;
}

.tech-background-image {
    background-image: url(../images/background.png);
}

.tech-background-image {
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.sidebar {
    width: 110px;
    position: fixed;
    /* background-color: #ff000029; */
    z-index: 1000;
}

.right-sidebar {
    right: 0px;
    width: 115px;
    height: 1080px;
    position: fixed;
    /* background-color: #ff000029; */
    z-index: 1000;
}

.credits {
    transform: rotate(-90deg);
    position: fixed;
    align-self: center;
    bottom: 121px;
    font-size: 13px;
}

.menu-button svg {
    width: 35px;
}

.c-circle {
    position: absolute;
    top: -5rem;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    opacity: 0.3;
    z-index: 0;
}

.sec-design {
    position: relative;
    padding-top: 10.9375rem;
    padding-bottom: 19.375rem;
}

.why-tech .icon svg {
    width: 35px;
    height: 35px;
    margin-bottom: 20px;
}

.sub-text {
    font-size: .9rem;
}

.video-mask {
    mask-image: url('../images/te.png');
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 100%;
    height: 450px;
    pointer-events: none;
    mask-position: 20px 6px;
}

.video-blend {
    background: linear-gradient( 135deg, rgb(0, 161, 255) 0%, rgb(0, 0, 0) 100%);
    mix-blend-mode: color;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: -webkit-fill-available;
    z-index: 1;
}

.why-image svg {
    width: 200px;
}

.why-image {
    position: absolute;
    top: -65px;
    left: 96px;
}

.career {
    background: linear-gradient(135deg, #C20936 -100%, #EA004C 110%);
}

.career-content {
    padding-bottom: 100px;
}

.simple-word-with-line::before {
    content: '';
    background-color: #fff;
    height: 1px;
    width: 100px;
    position: absolute;
    margin-top: 10px;
    margin-left: -120px;
}

.simple-word {
    margin-left: 120px;
    text-transform: uppercase;
    font-size: 14px;
}

.contact-content {
    margin-left: 120px;
    font-size: 14px;
}

.contact {
    background-color: #111 !important;
}

.contact .main-text {
    font-size: 100px;
    font-weight: bold;
    line-height: 1;
    margin-top: 80px;
}

.toTop {
    position: absolute;
    right: 160px;
    top: 100px;
    width: 50px;
    height: 50px;
    z-index: 100000;
}

.toTop svg {
    fill: #fff
}

.service .main-text {
    font-size: 100px;
    font-weight: bold;
    line-height: 1;
    margin-top: 80px;
}

.career .main-text {
    font-size: 100px;
    font-weight: bold;
    line-height: 1;
    margin-top: 80px;
}

.serviceContent .main-text {
    font-size: 120px;
    line-height: 1;
    padding-top: 80px;
    color: transparent;
    -webkit-text-stroke: 2px #000000f7;
    transition-duration: .5s;
    font-family: 'Anton', sans-serif;
}

.service .learnMore:hover {
    border-radius: 2px !important;
    transition-duration: .5s !important;
    padding: 5px 132px !important;
    background: white;
    color: #000 !important;
    font-weight: bold;
}

.service .learnMore {
    border: 2px solid rgb(255, 255, 255);
    padding: 5px 35px;
    border-radius: 100px;
    text-decoration: none;
    transition-duration: .5s;
}

.close-btn {
    position: absolute;
    right: 38px;
    margin-top: 28px;
}

.close-btn svg {
    width: 35px;
}

.close-btn svg .cls-1 {
    fill: #fff !important;
}

.navigation-root {
    margin-top: 60px;
}

.navigation-root .main-text {
    font-size: 60px;
    margin-top: 10px;
    color: #ffffff00;
    -webkit-text-stroke: 2px #ffffff4a;
    transition-duration: .5s;
    font-family: 'Anton', sans-serif;
}

.navigation-root .main-text:hover {
    font-size: 100px;
    margin-top: 15px;
    transition-duration: .5s;
    color: #ffffff;
    font-family: 'Anton', sans-serif;
}

.social-media {
    position: fixed;
    right: 150px;
    bottom: 100px;
    color: #fff;
}

.social-media::before {
    content: '';
    width: 100px;
    height: 1px;
    background-color: #fff;
    margin-top: 14px;
    margin-right: 14px;
}

.serviceContent {
    padding-bottom: 100px;
}

.service-head {
    border-bottom: 1px solid #bdbdbd;
}

.service-body {
    padding-bottom: 10px !important;
}

.career .icon svg {
    width: 50px;
    height: 50px;
}

.nowrap {
    white-space: nowrap;
}

.contrast {
    filter: contrast(0);
}

.radius-10 {
    border-radius: 10px;
}

.contact-us .background-image {
    background-image: url("../images/ContactUs.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
}

.main-text,
.main-contact-text {
    padding: 30px;
    padding-left: 50px;
}

.main-contact-text {
    margin: 0px;
    font-size: 85px;
    line-height: 1;
    font-weight: 700;
    color: #fff;
    font-family: "Calibri", sans-serif;
}

.main-text h1 {
    margin: 0px;
    font-size: 3rem;
    line-height: 1;
    font-weight: 700;
    color: #fff;
    text-shadow: 0px -1px 4px #00000047;
    font-family: "Rokkitt", serif;
    opacity: 0.5;
}

.animate-fly {
    animation: fly 5s infinite;
    position: relative;
}

@keyframes fly {
    0% {
        top: -5px;
    }
    50% {
        top: 5px;
    }
    100% {
        top: -5px;
    }
}

.animate-wave {
    animation: mymove 5s infinite;
    position: relative;
}

@keyframes mymove {
    0% {
        left: -10px;
        transform: rotate(-4deg);
    }
    50% {
        left: 10px;
        transform: rotate(4deg);
    }
    100% {
        left: -10px;
        transform: rotate(-4deg);
    }
}

.services-content,
.product-content {
    height: 100vh;
}

.about .icon svg {
    width: 40px;
    height: 40px;
    margin-bottom: 8px;
}

.about,
.contact-head {
    padding-bottom: 100px;
}

.contact-head {
    height: 100vh;
}