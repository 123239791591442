/* Extra small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {
    .tech-background-image {
        background-image: url(../images/sm-bg.png) !important;
    }
    .toTop {
        right: 50px;
        top: 85%;
    }
    .contact .main-text {
        font-size: 50px;
        margin-top: 35px;
    }
    .services-content,
    .product-content {
        height: auto;
    }
    .why-image {
        top: 125px;
        left: 24px;
    }
    .about .flex-reverse-sm .video-mask {
        -webkit-mask-position: 20px;
        height: 200px;
    }
    .why-image svg {
        width: 90px;
    }
    .flex-reverse-sm {
        flex-direction: column-reverse;
    }
    .c-circle {
        top: 8rem;
        width: 100%;
    }
    .right-sidebar {
        height: auto;
    }
    .career .main-text {
        padding: 0px;
        font-size: 55px;
    }
    .contact .main-text,
    .career .main-text {
        line-height: 1.2;
    }
    .sm-font {
        font-size: 18px;
    }
    .hide-on-sm {
        display: none;
    }
    .navigation-root .main-text {
        font-size: 45px;
        margin-top: -8px;
    }
    .navigation-root .main-text {
        font-size: 39px;
        margin-top: -16px;
        -webkit-text-stroke: 1px #ffffff4a;
    }
    .main-contact-text {
        font-size: 48px;
        width: 100% !important;
        height: 33% !important;
    }
    .about .icon {
        display: flex;
        justify-content: center;
    }
    .about .h6,
    .about .sub-text {
        text-align: center;
    }
}


/* Small devices (landscape phones, less than 768px) */

@media (max-width: 767.98px) {
    .video-mask {
        -webkit-mask-position: 20px;
        height: 200px;
    }
    .c-circle {
        top: 8rem;
        width: 100%;
    }
    .right-sidebar {
        height: auto;
    }
    .career .main-text {
        padding: 0px;
    }
    .career,
    .contact .main-text,
    .career .main-text {
        line-height: 1.2;
    }
}


/* Medium devices (tablets, less than 992px) */

@media (max-width: 991.98px) {
    .tech-background-image {
        background-image: url(../images/tab-bg.png);
    }
    .video-mask {
        -webkit-mask-position: 20px;
        height: 200px;
    }
    .c-circle {
        top: 8rem;
        width: 100%;
    }
    .right-sidebar {
        height: auto;
    }
    .career .main-text {
        padding: 0px;
    }
    .contact .main-text,
    .career .main-text {
        line-height: 1.2;
    }
    .why-image {
        top: -54px;
        left: 76px;
    }
    .about .video-mask {
        -webkit-mask-position: 20px;
        height: 400px;
    }
    .why-image svg {
        width: 169px;
    }
    .flex-reverse-sm {
        flex-direction: column-reverse;
    }
}


/* Large devices (desktops, less than 1200px) */

@media (max-width: 1199.98px) {
    .tech-background-image {
        background-image: url(../images/md-bg.png);
    }
    .why-image {
        top: 29px;
        left: 76px;
    }
    .video-mask {
        -webkit-mask-position: 20px;
        height: 258px;
        margin-top: 100px;
    }
    .why-image svg {
        width: 169px;
    }
    .about {
        padding-top: 100px;
    }
}